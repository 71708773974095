<template>
  <div v-if="this.acceso.ingreso">
    <v-card>
      <v-row>
        <v-col cols="8">
          <v-card-title class="person-name">
            {{ this.acceso.ingreso }}
          </v-card-title>
          <v-card-subtitle>
            {{ this.acceso.persona.nombres }}
            {{ this.acceso.persona.apellidos }}
          </v-card-subtitle>
        </v-col>
        <v-spacer> </v-spacer>
        <v-col class="text-right">
          <div class="text-no-wrap photo-container">
            <v-progress-circular
              v-if="this.loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-avatar size="80">
              <img
                v-if="this.foto"
                :src="this.foto.foto"
                alt="no img"
                class="photo-viewport"
              />
              <img
                v-else
                :src="require('../assets/no-photo.png')"
                alt="no profile picture"
              />
            </v-avatar>
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <br />
            <p>
              <strong>Nombre: </strong>{{ this.acceso.persona.nombres }}
              {{ this.acceso.persona.apellidos }}
              ({{ this.acceso.persona.get_documento_tipo_display }} -
              {{ this.acceso.persona.documento_nro }})
            </p>
            <p><strong>Fecha y Hora: </strong>{{ this.acceso.ingreso }}</p>
            <p><strong>Dispositivo: </strong>{{ this.acceso.author }}</p>
            <p v-if="this.acceso.lugar">
              <strong>Lugar: </strong>{{ this.acceso.lugar.nombre }}
            </p>
          </v-col>
        </v-row>

        <div v-if="this.foto_fichada">
          <v-row>
            <v-col>
              <v-card-title>Foto</v-card-title>
              <div class="text-center">
                <img
                  v-if="this.foto_fichada.foto"
                  width="60%"
                  :src="this.foto_fichada.foto"
                  alt="fichada foto"
                />
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="acceso.longitud && acceso.latitud">
          <v-row>
            <v-col>
              <v-card-title>Ubicación</v-card-title>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-if="acceso.lugar" class="text-left">Lugar</th>
                      <th class="text-left">Latitud</th>
                      <th class="text-left">Longuitud</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr @click="selectRow()">
                      <td v-if="acceso.lugar">
                        {{ acceso.lugar.nombre }}
                      </td>
                      <td>{{ acceso.latitud }}</td>
                      <td>{{ acceso.longitud }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :to="{ name: 'Fichadas' }"
          ><v-icon left darf>mdi-arrow-left</v-icon>Regresar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "FichadaCard",
  data() {
    return {
      foto: null,
      loading: true,
      foto_fichada: null,
    };
  },
  created() {
    EmpleadosService.getPersonaFoto(this.acceso.persona.id)
      .then((response) => {
        this.foto = response.data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
      });

    EmpleadosService.getFichadaFoto(this.acceso.id)
      .then((response) => {
        this.foto_fichada = response.data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
      });
  },
  methods: {
    selectRow() {
      let link = "https://www.google.com/maps/search/?api=1&query=";
      link = link + this.acceso.latitud + "," + this.acceso.longitud;
      window.open(link, "_blank");
    },
  },
  props: {
    acceso: {
      type: Object,
      required: true,
    },
  },
  computed: {
    nombreCompleto: function () {
      return this.acceso.persona.nombres + " " + this.acceso.persona.apellidos;
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
