<template>
  <v-container>
    <v-row>
      <v-col style="display: flex">
        <v-btn large icon :to="{ name: 'Fichadas' }"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <span class="text-h4">Detalle de Fichada</span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br />

    <div v-if="acceso">
      <FichadaCard :acceso="this.acceso"> </FichadaCard>
    </div>
  </v-container>
</template>

<script>
import FichadaCard from "../components/FichadaCard.vue";
import EmpleadosService from "@/services/EmpleadosService.js";

export default {
  name: "FichadaDetalle",
  components: {
    FichadaCard,
  },
  data() {
    return {
      acceso: null,
    };
  },
  props: ["id"],
  created() {
    EmpleadosService.getFichada(this.id)
      .then((response) => {
        this.acceso = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
